import { I_ThemeAction } from '../interfaces/theme';

const initialState: string = 'light';

export const themeReducer = (state: string = initialState, action: I_ThemeAction): string => {
  switch (action.type) {
    case 'light':
      return action.payload;
    case 'dark':
      return action.payload;
    default:
      return state;
  }
};
