import React from 'react'
import { registerDOMHandlers } from '../util';

interface I_MainWrapperProps {
  children: React.ReactNode;
  reduxStore: any;
}

declare global {
    interface Window {
        unsubscribeToMainContentLoadedEvents: any;
    }
}

const subscribeToMainContentLoadedEvents = (reduxStore: any) => {
  const mainContentLoaded: boolean = reduxStore.getState().loaded;
  if(mainContentLoaded){
    registerDOMHandlers();
    window.unsubscribeToMainContentLoadedEvents();
  }
}

const MainWrapper: React.FC<I_MainWrapperProps> = ({children, reduxStore}):JSX.Element => {
  React.useEffect(() => {
    // window.unsubscribeToMainContentLoadedEvents = reduxStore.subscribe(() => subscribeToMainContentLoadedEvents(reduxStore));
    return () => {}
  }, [])

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default MainWrapper;