export const LIGHT_THEME: string = 'light';
export const DARK_THEME: string = 'dark';

export interface I_ObjectKeys {
  [key: string]: string;
}

export interface I_ImageProps {
  src: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  href?: string;
  className?: string;
  name?: string;
}

// Images

const HOME_IMAGE_SRC: string = 'person-with-screens.png';
const WORK_IMAGE_SRC: string = 'angry-laptop-man.png';
const CONTACT_IMAGE_SRC: string = 'envelope-boy.png';
const PAGE_NOT_FOUND_IMAGE_SRC: string = '404.png';

export const HOME_IMAGE_PROPS: I_ImageProps = {
  src: HOME_IMAGE_SRC,
  width: 350,
  height: 350,
  alt: 'Home hero image',
};

export const WORK_IMAGE_PROPS: I_ImageProps = {
  src: WORK_IMAGE_SRC,
  width: 350,
  height: 350,
  alt: 'Work side image',
};

export const CONTACT_IMAGE_PROPS: I_ImageProps = {
  src: CONTACT_IMAGE_SRC,
  width: 350,
  height: 350,
  alt: 'Contact image',
  className: `contact_image`,
};

export const PAGE_NOT_FOUND_IMAGE_PROPS: I_ImageProps = {
  src: PAGE_NOT_FOUND_IMAGE_SRC,
  width: 350,
  height: 350,
  alt: 'Page not found image',
  className: `page_not_found_image`,
};

// logos

const LINKEDIN_LOGO_SRC: string = 'linkedin-logo.png';
const GITHUB_LOGO_SRC: string = 'github-logo.png';
const GMAIL_LOGO_SRC: string = 'gmail-logo.png';
const TWITTER_LOGO_SRC: string = 'twitter-logo.png';
const INSTAGRAM_LOGO_SRC: string = 'instagram-logo.png';

export const LINKEDIN_IMAGE_PROPS: I_ImageProps = {
  src: LINKEDIN_LOGO_SRC,
  width: 40,
  height: 40,
  alt: 'LinkedIn logo',
  href: 'https://linkedin.com/in/liltrendi',
  className: `footer_logo_dimensions`,
  name: 'LinkedIn',
};

export const GITHUB_IMAGE_PROPS: I_ImageProps = {
  src: GITHUB_LOGO_SRC,
  width: 40,
  height: 40,
  alt: 'Github logo',
  href: 'https://github.com/liltrendi',
  className: `footer_logo_dimensions`,
  name: 'Github',
};

export const GMAIL_IMAGE_PROPS: I_ImageProps = {
  src: GMAIL_LOGO_SRC,
  width: 40,
  height: 40,
  alt: 'Gmail logo',
  href: 'mailto:briancanspit@gmail.com',
  className: `footer_logo_dimensions`,
  name: 'Gmail',
};

export const TWITTER_IMAGE_PROPS: I_ImageProps = {
  src: TWITTER_LOGO_SRC,
  width: 40,
  height: 40,
  alt: 'Twitter logo',
  href: 'https://twitter.com/liltrendi',
  className: `footer_logo_dimensions`,
  name: 'Twitter',
};

export const INSTAGRAM_IMAGE_PROPS: I_ImageProps = {
  src: INSTAGRAM_LOGO_SRC,
  width: 40,
  height: 40,
  alt: 'Instagram logo',
  href: 'https://instagram.com/liltrendi',
  className: `footer_logo_dimensions`,
  name: 'Instagram',
};

export const FOOTER_LOGOS: I_ImageProps[] = [
  GITHUB_IMAGE_PROPS,
  LINKEDIN_IMAGE_PROPS,
  INSTAGRAM_IMAGE_PROPS,
  TWITTER_IMAGE_PROPS,
  GMAIL_IMAGE_PROPS,
];
