import { I_LoadedActionProps } from '../actions/welcome/interfaces'

const initialState: boolean = false;

export const loadedReducer = (state: boolean = initialState, action: I_LoadedActionProps): boolean => {
  switch(action.type){
    case "LOAD_MAIN_CONTENT":
      return true;
    default:
      return state;
  }
}