import React, { useCallback  } from "react"
import { isInLastLoopIteration } from "../util";

export interface I_WorkExperience {
  order: number;
  company: string;
  role: string;
  startDate: Date;
  endDate?: Date;
  Summary: ({ className }: I_WorkExperienceComponentsSharedProps) => JSX.Element;
  Duties?: ({ className }: I_WorkExperienceComponentsSharedProps) => JSX.Element;
  Tools?: ({ className }: I_WorkExperienceComponentsSharedProps) => JSX.Element;
  website: string;
}

interface I_WorkExperienceComponentsSharedProps {
  className?: string;
  toolClassName?: string;
  toolSeparatorClassName?: string;
}

const dutiesStyle = {
  listStyle: "none",
  paddingLeft: 0,
}

const kenyanWallstreetWorkExperience: I_WorkExperience = {
  order: 1,
  company: "The Kenyan Wallstreet",
  role: "React Native Intern",
  startDate: new Date("01/01/2020"),
  endDate: new Date("01/28/2020"),
  Summary: ({className}: I_WorkExperienceComponentsSharedProps) => (
    <p className={className}>
      I actively participated in the conceptual phase of their MVP cross-platform financial mobile application, which included:
    </p>
  ),
  Duties: ({className}: I_WorkExperienceComponentsSharedProps) => {
    const duties = ["Participated in the early prototype and build of <a href='https://play.google.com/store/apps/details?id=com.kenyanwallstreet.hisa'>HISA</a>, an app that champions for borderless investments by allowing people within Africa to buy and sell international stocks."];
    return (
      <ul style={dutiesStyle} className={"work_duties"}>
        {duties?.map((duty, index) => <li key={index} className={className} dangerouslySetInnerHTML={{__html: `<em>• ${duty}</em>`}} />)}
      </ul>
    )
  },
  Tools: ({className, toolClassName, toolSeparatorClassName}: I_WorkExperienceComponentsSharedProps) => {
    const ToolsList = useCallback(() => {
      const tools: string[] = ["Figma", "React Native"];

      return (
        <>
          {tools?.map((tool, index) => (
            <span key={index} className={toolClassName}>
              {tool}
              <span className={toolSeparatorClassName}>
                {isInLastLoopIteration(index, tools) ? "" : ", "}
              </span>
            </span>
          ))}
        </>
      )
    }, [className, toolClassName, toolSeparatorClassName]);

    return (
      <p className={`${className} work_tooling`}>
        Tooling: <ToolsList />.
      </p>
    )
  },
  website: "https://kenyanwallstreet.com/"
}

const granularITWorkExperience: I_WorkExperience = {
  order: 2,
  company: "Granular",
  role: "Full Stack Engineer",
  startDate: new Date("02/01/2020"),
  endDate: new Date("02/25/2022"),
  Summary: ({className}: I_WorkExperienceComponentsSharedProps) => (
    <p className={className}>
      I was tasked with working with (and sometimes leading) a team of backend and frontend engineers to provide scalable solutions for clients. Here are some of my highlights:
    </p>
  ),
  Duties: ({className}: I_WorkExperienceComponentsSharedProps) => {
    const duties = ["Steered the team that built an AWS-based event booking <a href='https://mhm-meeting.com' target='_blank'>platform</a> that served at least 1000+ medical professionals, positively spiking the booking rate by 12%.", "Lead the rebuild of a legacy online meetings medical <a href='https://the-corpus.com' target='_blank'>web app</a> whose new features, that had previously been shelved due to integration issues with the existing architecture, drove attendance and engagement rates by as much as 30%.", "Pioneered the development of a cancer-support <a href='https://farajaraftingchallenge.org' target='_blank'>PWA</a> that helped fundraise about $50k by the end of 2021.", "Provided extensive support for a user-heavy Shopify <a href='https://youngsoles.london' target='_blank'>site</a>, with my best highlight being the rewrite of a product search feature drastically reducing customer churn rate by 25%.", "Migrated, redesigned and rebuilt a legacy <a href='https://diurnal.co.uk'>project</a> to a more modern and cloud-based stack, saving the client thousands of pounds in recurring expenses."];
    return (
      <ul style={dutiesStyle} className={"work_duties"}>
        {duties?.map((duty, index) => <li key={index} className={className} dangerouslySetInnerHTML={{__html: `<em>• ${duty}</em>`}} />)}
      </ul>
    )
  },
  Tools: ({className, toolClassName, toolSeparatorClassName}: I_WorkExperienceComponentsSharedProps) => {
    const ToolsList = useCallback(() => {
      const tools: string[] = ["JavaScript", "jQuery", "TypeScript", "React", "React Native", "GraphQL", "Node.js", "Gatsby.js", "Netlify", "Contentful", "Shopify", "Liquid", "AWS Amplify", "DynamoDB", "Cognito", "S3", "API Gateway", "Lambda", "IAM", "CodeCommit", "Git", "Azure", "Umbraco", "C#"];

      return (
        <>
          {tools?.map((tool, index) => (
            <span key={index} className={toolClassName}>
              {tool}
              <span className={toolSeparatorClassName}>
                {isInLastLoopIteration(index, tools) ? "" : ", "}
              </span>
            </span>
          ))}
        </>
      )
    }, [className, toolClassName, toolSeparatorClassName]);

    return (
      <p className={`${className} work_tooling`}>
        Tooling: <ToolsList />.
      </p>
    )
  },
  website: "https://granularit.com/"
}

const shopDoctorsWorkExperience: I_WorkExperience = {
  order: 3,
  company: "Shop Doctors",
  role: "Frontend Developer",
  startDate: new Date("01/13/2023"),
  endDate: new Date("02/09/2023"),
  Summary: ({className}: I_WorkExperienceComponentsSharedProps) => (
    <p className={className}>
      I consulted for a US-based startup that specializes in building Shopify apps
    </p>
  ),
  Duties: ({className}: I_WorkExperienceComponentsSharedProps) => {
    const duties = ["Extended an existing functionality from their all-in-one discounts <a href='https://apps.shopify.com/discount-on-cart-pro' target={'_blank'}>app</a> to their all-in-one volume discounts <a href='https://apps.shopify.com/all-in-one-volume-discounts' target={'_blank'}>app</a>, that allows customers to manually add multiple discount codes during checkout, reducing cart abandonment and boosting sales."];

    return (
      <ul style={dutiesStyle} className={"work_duties"}>
        {duties?.map((duty, index) => <li key={index} className={className} dangerouslySetInnerHTML={{__html: `<em>• ${duty}</em>`}} />)}
      </ul>
    )
  },
  Tools: ({className, toolClassName, toolSeparatorClassName}: I_WorkExperienceComponentsSharedProps) => {
    const ToolsList = useCallback(() => {
      const tools: string[] = ["JavaScript", "Shopify", "PHP", "Laravel"];

      return (
        <>
          {tools?.map((tool, index) => (
            <span key={index} className={toolClassName}>
              {tool}
              <span className={toolSeparatorClassName}>
                {isInLastLoopIteration(index, tools) ? "" : ", "}
              </span>
            </span>
          ))}
        </>
      )
    }, [className, toolClassName, toolSeparatorClassName]);

    return (
      <p className={`${className} work_tooling`}>
        Tooling: <ToolsList />.
      </p>
    )
  },
  website: "https://apps.shopify.com/partners/shop-doctors"
}

const distributedWorkExperience: I_WorkExperience = {
  order: 4,
  company: "Distributed",
  role: "Frontend Engineer",
  startDate: new Date("03/01/2022"),
  Summary: ({className}: I_WorkExperienceComponentsSharedProps) => (
    <p className={className}>
      I consult for multiple clients across Europe alongside talented individuals to build & ship quality web apps, as part of their global Elastic Teams division.
    </p>
  ),
  Duties: ({className}: I_WorkExperienceComponentsSharedProps) => {
    const duties = ["Documented a <a href='https://khws.co.uk' target='_blank'>client</a>’s complex excel-based cost calculators, built custom web-apps integrated with an enterprise sales solution, and deployed release pipelines, enhancing overall salesperson experience.","Spearheaded the frontend development for an active intelligence geomapping utility service for <a href='https://activeintelligence.bt.com/' target={'_blank'}>BT</a>, taking demographic analysis speed from an hour to a couple of minutes.", "Delivered a working insurance aggregation <a href='https://breezeegroup.com' target={'_blank'}>prototype</a> for an undisclosed funding round.","Built real-time, data-driven analytics software for <a href='https://lytt.com' target='_blank'>Lytt</a>, to visualize fiber data in the oil and gas sector, reducing driller collisions by nearly 50%."];

    return (
      <ul style={dutiesStyle} className={"work_duties"}>
        {duties?.map((duty, index) => <li key={index} className={className} dangerouslySetInnerHTML={{__html: `<em>• ${duty}</em>`}} />)}
      </ul>
    )
  },
  Tools: ({className, toolClassName, toolSeparatorClassName}: I_WorkExperienceComponentsSharedProps) => {
    const ToolsList = useCallback(() => {
      const tools: string[] = ["JavaScript", "TypeScript", "Node.js", "React", "Material UI", "Tailwind", "Apollo", "GraphQL", "REST APIs", "Sockets", "WebGL", "SciChart.js", "Recharts", "Showpad", "Web Assembly", "Jest", "Storybook", "Strapi", "Gitlab", "Azure DevOps", "Confluence", "Jira"];

      return (
        <>
          {tools?.map((tool, index) => (
            <span key={index} className={toolClassName}>
              {tool}
              <span className={toolSeparatorClassName}>
                {isInLastLoopIteration(index, tools) ? "" : ", "}
              </span>
            </span>
          ))}
        </>
      )
    }, [className, toolClassName, toolSeparatorClassName]);

    return (
      <p className={`${className} work_tooling`}>
        Tooling: <ToolsList />.
      </p>
    )
  },
  website: "https://distributed.com/"
}

export const WORK_EXPERIENCES: I_WorkExperience[] = [
  kenyanWallstreetWorkExperience,
  granularITWorkExperience,
  shopDoctorsWorkExperience,
  distributedWorkExperience
]
