import { I_HeaderActionProps } from "../actions/header/interfaces";

const initialState: boolean = false;

export const headerReducer = (state: boolean = initialState, action: I_HeaderActionProps): boolean => {
  switch(action.type){
    case "OPEN_NAVIGATION_MENU":
      return true;
    case "CLOSE_NAVIGATION_MENU":
      return false;
    default:
      return state;
  }
}