import {combineReducers} from "redux"
import { headerReducer } from "./header"
import { loadedReducer } from "./loaded"
import { themeReducer } from './theme'

const rootReducer = combineReducers({
  loaded: loadedReducer,
  theme: themeReducer,
  navigationActive: headerReducer
})

export default rootReducer