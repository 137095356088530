import React from 'react'
import { createStore, applyMiddleware, Store } from 'redux'
import { Provider } from 'react-redux'
import thunk from "redux-thunk"
import { 
    // persistStore,
     persistReducer } from 'redux-persist'
// import { PersistGate } from 'redux-persist/integration/react'
// import { Persistor } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import MainWrapper from './main';
import rootReducer from "../reducers"
import { I_InitialState, I_PersistConfig, I_ProvideStoresProps } from '../interfaces/ReduxWrapper';

const initialState: I_InitialState = {};

const persistConfig: I_PersistConfig = {
    key: "brian_njogu_portfolio_root_store",
    storage: storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default ({ element }: I_ProvideStoresProps) => {
    // const duringBuild: boolean = typeof window === "undefined";
    const store: Store = createStore(persistedReducer, initialState, applyMiddleware(thunk));
    // const persistor: Persistor = persistStore(store);

    // persistor.purge()

    // apparently persist-gate affects SEO meta placement, so commenting out code
    // see: https://github.com/gatsbyjs/gatsby/issues/10414#issuecomment-448302435
    // how to fix: https://stackoverflow.com/a/62501513

    // commenting out due to double render when duringBuild var changes

    // if(!duringBuild){
    //     return (
    //         <MainWrapper reduxStore={store}>
    //             <PersistGate loading={null} persistor={persistor}>
    //                 <Provider store={store}>
    //                     {element}    
    //                 </Provider>
    //             </PersistGate>
    //         </MainWrapper>
    //     )
    // }

    return (
        <MainWrapper reduxStore={store}>
            <Provider store={store}>
                {element}    
            </Provider>
        </MainWrapper>
    )
}