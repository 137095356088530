import { TweenMax } from 'gsap';
import moment, {Moment} from "moment"
import { I_AllRefs } from '../components/shared/layout';
import { LIGHT_THEME } from "../config/constants";
import useOnScreen from './hooks';
import { I_WorkExperience,  WORK_EXPERIENCES } from '../config/work';

export const logStrokeDashArrays = (childNodes: Array<any> | null | undefined): void => {
  if(!childNodes) return;
  for(let node of childNodes){
    console.log(`Length: ${node.getTotalLength()}`);
  }
}

export const isLightTheme = (theme: string | undefined | null): boolean => {
  if(!theme) return true;
  return theme === LIGHT_THEME;
}

export const cursorDOMHandler = (bigBall: any, smallBall: any, hoverables: any, intervalId: any):void => {

  clearInterval(intervalId);

  const onMouseMove = (e: any): void => {
    TweenMax.to(bigBall, .4, {
      x: e.pageX - 15,
      y: e.pageY - 15
    })
    TweenMax.to(smallBall, .1, {
      x: e.pageX - 5,
      y: e.pageY - 7
    })
  }

  const onMouseHover = (): void => {
    TweenMax.to(bigBall, .3, {
      scale: 3
    })
  }

  const onMouseHoverOut = (): void => {
    TweenMax.to(bigBall, .3, {
      scale: 1
    })
  }

  document.body.addEventListener('mousemove', onMouseMove);
  for (let i = 0; i < hoverables.length; i++) {
    hoverables[i].addEventListener('mouseenter', onMouseHover);
    hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
  }
}

export const registerDOMHandlers = (): void => {
  let intervalId = setInterval(() => {
    let bigBall = document.querySelector('.cursor__ball__big');
    let smallBall = document.querySelector('.cursor__ball__small');
    let hoverables = document.querySelectorAll('.hoverable');

    if(bigBall && smallBall){
      cursorDOMHandler(bigBall, smallBall, hoverables, intervalId);
      return;
    }
  }, 500)
}

export const scrollToSection = async (allRefs: I_AllRefs, section: string): Promise<void> => {
  const {intro, about, work, projects, blog, contact} = allRefs;
 
  switch(section){
    case "intro":
      if(intro?.current) intro.current.scrollIntoView({ behavior: 'smooth' });
      else break;
      return;
    case "about":
      if(about?.current) window.scrollTo(0, about.current.offsetTop - 50);
      else break;
      return;
    case "work":
      if(work?.current) window.scrollTo(0, work.current.offsetTop - 200);
      else break;
      return;
    case "projects":
      if(projects?.current) projects.current.scrollIntoView({ behavior: 'smooth' });
      else break;
      return;
    case "blog":
      if(blog?.current) blog.current.scrollIntoView({ behavior: 'smooth' });
      else break;
      return;
    case "contact":
      if(contact?.current) window.scrollTo(0, contact.current.offsetTop - 70);
      else break;
      return;
  }
  
  window.location.href = `/#${section}`;
}

export const isSectionVisible = (allRefs: I_AllRefs, section: string): boolean => {
  const visible: boolean = useOnScreen((allRefs as any)[`${section}`]);
  return visible;
}

export const getFormattedWorkDatesBetween = (workExperience: I_WorkExperience): string => {
  const {startDate, endDate} = workExperience;

  let formattedStartDate: string = moment(startDate)?.format("MMM YYYY");
  let formattedEndDate: string = endDate ? moment(endDate)?.format("MMM YYYY") : "Present";

  if(formattedStartDate === formattedEndDate){
    return `${formattedStartDate}`;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export const getFormattedWorkPeriodLength = (workExperience: I_WorkExperience): string => {
  const {startDate, endDate} = workExperience;

  let dateThen: Moment = moment(startDate);
  let dateToday: Moment = endDate ? moment(endDate) : moment();

  let yearDifference: number = dateToday.diff(dateThen, "years");
  let monthDifference: number = dateToday.diff(dateThen, "months");

  let years: number = yearDifference;
  let months: number = (monthDifference % 12) + 1;

  let yearsToDisplay = years <= 0 ? "" : years >= 2 ? `${years}yrs` : `${years}yr`;
  let monthsToDisplay = months === 0 ? "" : yearsToDisplay === "" ? `${months}mos` : ` ${months}mos`;
  
  let formattedWorkPeriod = `${yearsToDisplay}${monthsToDisplay}`;

  if(!formattedWorkPeriod){
    return "1mos";
  }

  if(formattedWorkPeriod === "12mos"){
    return "1yr"
  }

  return formattedWorkPeriod;
}

export const sortWorkExperiencesByOrder = (first: I_WorkExperience, second: I_WorkExperience) => {
  if(first?.order > second?.order) return -1;
  else if(second?.order > first?.order) return 1;
  return 0;
}

export const isInLastLoopIteration = (index: number, array: any[]): boolean => {
  return index === array.length - 1
}

export const getTotalYearsOfExperience = () => {
  const earliestStartDate = WORK_EXPERIENCES[0].startDate;

  let yearDifference: number = moment().diff(new Date(earliestStartDate), "years");

  if(yearDifference){
    return `4 years`
    // return `${yearDifference}+ years`
  }

  return "Several years";
}